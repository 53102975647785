"use client";

import {} from "@radix-ui/react-dialog";
import { VariantProps, cva } from "class-variance-authority";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/components/ui/alert-dialog";
import { cn } from "~/lib/utils";
import { Button } from "./button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./dialog";

export function ConfirmationModal(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  description: string;
  title?: string;
  okText?: string;
  cancelText?: string;
}) {
  const { open, setOpen, onConfirm, title, description, okText, cancelText } =
    props;

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title ?? "Confirm"}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setOpen(false)}>
            {cancelText ?? "Cancel"}
          </AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive-foreground hover:bg-destructive-foreground/80"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
          >
            {okText ?? "OK"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

const modalVariants = cva("", {
  variants: {
    size: {
      sm: "max-w-[500px]",
      md: "max-w-[700px]",
      lg: "max-w-[900px]",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export interface BaseModalProps extends VariantProps<typeof modalVariants> {
  open?: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
  title: string;
}

export function BaseModal(props: BaseModalProps) {
  const { open, setOpen, children, title, size } = props;
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className={cn(modalVariants({ size }))}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export interface ConfirmModalProps extends VariantProps<typeof modalVariants> {
  open?: boolean;
  setOpen: (open: boolean) => void;
  onOk: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  title: string;
  cancelText?: string;
  okText?: string;
}

export function ConfirmModal(props: ConfirmModalProps) {
  const {
    open,
    setOpen,
    onOk,
    children,
    title,
    size,
    cancelText,
    okText,
    onCancel,
  } = props;
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className={cn(modalVariants({ size }))}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {children}

        <DialogFooter>
          <Button
            variant={"outline"}
            onClick={() => {
              setOpen(false);
              onCancel && onCancel();
            }}
          >
            {cancelText ?? "Cancel"}
          </Button>
          <Button
            className="bg-primary-foreground hover:bg-primary-foreground/80"
            onClick={() => {
              onOk();
            }}
          >
            {okText ?? "OK"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
